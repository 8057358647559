import React from "react";
import {createButton} from "react-social-login-buttons";

const config = {
    text: "Signup",
    icon: "facebook",
    iconFormat: name => `fa fa-${name}`,
    style: {background: "green"},
    activeStyle: {background: "red"},
    textAlign: "center",
};

const SignupButton = createButton(config);

export default SignupButton;