import {useDispatch, useSelector} from "react-redux";
import {authAction} from "../Redux/AuthSlice";
import APIEndpoint from "./APIEndpoint";
import useHTTP from "./useHTTP";
import {toast} from "react-toastify";

const useAuthAPI = () => {
    const dispatch = useDispatch();
    const accessToken = useSelector((redux) => redux.auth.accessToken);
    const authAPI = useHTTP({
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
        },
    });

    const signUp = async (signupRequest) => {
        try {
            const response = await authAPI.post(
                APIEndpoint.ThangChibaAuth + "/signup",
                signupRequest
            );
            toast("Register success");
            return response;
        } catch (error) {
            alert("Register failed : " + error.toString());
        }
    };

    const signIn = async (username, password) => {
        try {
            const response = await authAPI.post(
                APIEndpoint.ThangChibaAuth + "/login",
                {
                    username,
                    password,
                }
            );
            dispatch(authAction.setAuth({AccessToken: response.accessToken}));
            localStorage.setItem("refreshToken", response.refreshToken);
            toast("Login Success");
            return response;
        } catch (error) {
            alert(error.toString());
        }
    };
    const loginOAuth = async (oauthType, accessToken) => {
        let isSuccess = false;
        try {
            if (accessToken) {
                const responseSIGNIN = await fetch(APIEndpoint.ThangChibaOAuth + '/' + oauthType, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({token: accessToken}),
                    credentials: "include"
                });

                // Check if the request was successful
                if (!responseSIGNIN.ok) {
                    throw new Error("Login Failed!!!");
                }

                const data = await responseSIGNIN.json();
                console.log(data);
                dispatch(authAction.setAuth({AccessToken: data.accessToken}));
                localStorage.setItem("refreshToken", data.refreshToken);
                toast("Login Success");
                isSuccess = true;
            }
        } catch (error) {
            alert(error.toString());
        }
        return isSuccess;
    };

    const getUser = async (accessToken) => {
        const response = await authAPI.get(APIEndpoint.ThangChibaAuth + "/user");
        console.log(response);
    };

    const refreshToken = async () => {
        const refreshToken = localStorage.getItem("refreshToken");
        const response = await authAPI.post(
            APIEndpoint.ThangChibaAuth + "/login-refresh",
            {
                refreshToken,
            }
        );
        dispatch(authAction.setAuth({AccessToken: response.accessToken}));
    };

    const logout = async () => {
        const refreshToken = localStorage.getItem("refreshToken");
        dispatch(authAction.clearAuth());
        try {
            console.log("logout token is ", refreshToken);
            const response = await authAPI.post(
                APIEndpoint.ThangChibaAuth + "/logout",
                {
                    refreshToken
                }
            );
            toast("Logout success");
            return response;
        } catch (error) {
            toast("Logout Failed : " + error.toString());
        }
    };

    return {
        signUp,
        signIn,
        loginOAuth,
        getUser,
        refreshToken,
        logout,
    };
};
export default useAuthAPI;
