import { InputLabel, Slider } from "@mui/material";
import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gptAction } from "../../../../../Redux/GPTSlice";

const MaxGenToken = () => {
  const dispatch = useDispatch();
  const maxGenToken = useSelector((redux) => redux.gpt.maxGenToken);
  const handleMaxTokenChange = (event) => {
    dispatch(gptAction.setMaxGenToken(event.target.value));
  };

  return (
    <div>
      <InputLabel
        sx={{ color: "white" }}
        id="max-gen-token-slider-label"
      >{`Max Generate Token : ${maxGenToken}`}</InputLabel>
      <Slider
        value={maxGenToken}
        onChange={handleMaxTokenChange}
        aria-labelledby="max-gen-token-slider-label"
        step={1}
        min={200}
        max={4000}
        color="warning"
      />
    </div>
  );
};
export default MaxGenToken;
