import { createSlice } from "@reduxjs/toolkit";

const InitialState = {
  isLogin: false,
  accessToken: null,
  idToken: null,
  accessKey: "freetoken",
  avatar:null,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState: InitialState,
  reducers: {
    setAuth(state, action) {
      const { IdToken, AccessToken,Avatar } = action.payload;
      state.accessToken = AccessToken;
      state.idToken = IdToken;
      state.isLogin = true;
      state.avatar = Avatar;
    },
    setAccessKey(state, action) {
      state.accessKey = action.payload;
    },
    clearAuth(state, action) {
      state.accessToken = null;
      state.idToken = null;
      state.isLogin = false;
      state.avatar = null;
      localStorage.removeItem("refreshToken");
    },
  },
});

export const authAction = AuthSlice.actions;
export default AuthSlice;
