import { createSlice } from "@reduxjs/toolkit";

const InitialState = {
  maxGenToken: 2000,
  temperature: 0.7,
  showAvatar: true,
  model: "gpt-3.5-turbo",
};

const GPTSlice = createSlice({
  name: "gpt",
  initialState: InitialState,
  reducers: {
    setMaxGenToken(state, action) {
      state.maxGenToken = action.payload;
    },
    setTemperature(state, action) {
      state.temperature = action.payload;
    },
    toggleShowAvatar(state) {
      state.showAvatar = !state.showAvatar;
    },
    setModel(state, action) {
      state.model = action.payload;
    },
  },
});

export const gptAction = GPTSlice.actions;
export default GPTSlice;
