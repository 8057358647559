import { useState } from "react";
import { toast } from "react-toastify";

const useHTTP = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const headers = props?.headers || {};
  const responseType = props?.responseType || "json";

  const sendRequest = async (method, url, body = null) => {
    try {
      setLoading(true);
      let request = {
        method: method,
        headers: headers,
      };
      if (body !== null && body) request.body = JSON.stringify(body);
      return new Promise((resolve, reject) => {
        fetch(url, request)
          .then((response) => {
            setLoading(false);
            if (responseType === "json") {
              if (!response.ok) {
                response
                  .json()
                  .then((data) => {
                    throw new Error(
                      data.error || "Network response was not ok"
                    );
                  })
                  .catch((error) => {
                    reject(error);
                  });
              } else {
                response
                  .json()
                  .then((data) => {
                    resolve(data);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              }
            } else {
              if (!response.ok) {
                response
                  .json()
                  .then((data) => {
                    toast(data.detail);
                    throw new Error(
                      data.detail || "Network response was not ok"
                    );
                  })
                  .catch((error) => {
                    reject(error);
                  });
              } else {
                resolve(response);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return {
    get: (url = "") => sendRequest("GET", url),
    post: (url = "", body) => sendRequest("POST", url, body),
    put: (url = "", body) => sendRequest("PUT", url, body),
    delete: (url = "", body) => sendRequest("DELETE", url, body),
    loading,
    error,
  };
};

export default useHTTP;
