import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import Advance from "./Advance";
import SetupToken from "./SetupToken";
import ShowAvatar from "./ShowAvatar";
import SpeakSetting from "./Speaker/SpeakSetting";
const Setting = () => {
  const [open, setOpen] = useState(false);
  const [youtube, setYoutube] = useState(true);
  const [newUpdate, setNewUpdate] = useState(true);
  function handleCloseYoutube() {
    setOpen(true);
  }
  function handleCloseNewUpdate() {
    // setOpen(true);
  }
  return (
    <>
      <Stack spacing={3} sx={{ padding: 2 }}>
        <Typography textAlign="center" variant="h3">
          Setting
        </Typography>
        <ShowAvatar />
        <SetupToken />
        <SpeakSetting />
        <Advance />
      </Stack>
    </>
  );
};
export default Setting;
