import { Button, styled } from "@mui/material";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import useAuthAPI from "../../../HTTP_Request/useAuthAPI";
import Login from "./Login";
import UserAvatar from "./UserAvatar";
const CustomerInfoBar = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

function CustomerInfo() {
  const isLogin = useSelector((redux) => redux.auth.isLogin);
  const [isOpen, setOpen] = React.useState(false);
  const authAPI = useAuthAPI();
  function HandlerLogOut() {
    authAPI.logout();
  }

  // Everytime reload page then check and refresh token
  React.useEffect(() => {
    authAPI.refreshToken();
  }, []);

  return (
    <Fragment>
      <CustomerInfoBar>
        {isLogin && <UserAvatar LogOut={HandlerLogOut} />}
        {!isLogin && (
          <Button
            variant="outlined"
            color="success"
            onClick={() => {
              setOpen(true);
            }}
          >
            Login
          </Button>
        )}
      </CustomerInfoBar>
      <Login open={isOpen} setOpen={setOpen} />
    </Fragment>
  );
}

export default CustomerInfo;
