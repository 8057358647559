import {MenuItem, Select} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {gptAction} from "../../../../Redux/GPTSlice";

const SetupModel = () => {
    const dispatch = useDispatch();
    const model = useSelector((redux) => redux.gpt.model);

    function handleChange(e) {
        console.log(e.target.value);
        dispatch(gptAction.setModel(e.target.value));
    }

    return (
        <Select
            labelId="GPT Model"
            value={model}
            onChange={handleChange}
            color="warning"
            sx={{
                color: "white",
            }}
        >
            <MenuItem value="gpt-3.5-turbo">GPT-3.5-Turbo</MenuItem>
            <MenuItem value="gpt-3.5-turbo-16k">GPT-3.5-16K</MenuItem>
            <MenuItem value="gpt-4">GPT-4</MenuItem>
            <MenuItem value="gpt-4-32k">GPT-4-32K</MenuItem>
            <MenuItem value="gpt-4-1106-preview">GPT-4-Turbo</MenuItem>
            <MenuItem value="gpt-4-vision-preview">GPT-4-Turbo-Vision</MenuItem>
        </Select>
    );
};
export default SetupModel;
