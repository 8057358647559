import styled from "@emotion/styled";
import {Grid, Paper} from "@mui/material";
import {Box} from "@mui/system";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {v4 as uuidv4} from "uuid";
import useHTTPGPTTC from "../../HTTP_Request/useHTTPGPTTC";
import ChatBoard from "./ChatBoard";
import ChatForm from "./ChatForm";
import LeftDrawer from "./LeftDrawer";
import RightDrawer from "./RightDraw";
import useSpeak from "./RightDraw/Setting/Speaker/useSpeak";
import ScrollDownButton from "./ScrollDownButton";
import LoginButton from "./Login/LoginButton";

const StyledCover = styled(Box)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        height: "100vh",
    },
    color: "white",
    height: "calc(120vh - 70px)",
    minWidth: "100vw",
    maxWidth: "100vw",
    margin: 0,
    paddingInline: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    backgroundColor: "#242526",
}));

const StyledChatBox = styled(Box)(({theme}) => ({
    height: "calc(100vh - 70px)",
    width: "100vw",
    maxWidth: "100vw",
    color: "white",
    margin: 0,
    paddingBlock: "35px",
    paddingInline: "20px",
    backgroundColor: "#242526",
    [theme.breakpoints.down("sm")]: {
        minWidth: "100vw",
        maxWidth: "100vw",
        height: "83vh",
        paddingBlock: "0px",
        paddingInline: "0px",
    },
    position: "relative",
}));

const ChatGPTTC = (props) => {
    const speaker = useSpeak();
    const speak = useSelector((redux) => redux.speak);
    const teach = useSelector((redux) => redux.teach);
    const gptAPI = useHTTPGPTTC();
    const [openChatBox, setOpenChatBox] = useState(true);
    const [messages, setMessages] = useState(
        JSON.parse(localStorage.getItem("messages")) || []
    );

    useEffect(() => {
        localStorage.setItem("messages", JSON.stringify(messages));
    }, [messages]);

    async function submitChat(content) {
        try {
            const message = {
                id: uuidv4(),
                role: "user",
                content: content,
            };
            let newMessages = [...messages, message];
            setMessages(newMessages);
            const response = await gptAPI.post(
                teach
                    .map((t) => ({role: "system", content: t.content}))
                    .concat(
                        newMessages.slice(-8).map((message) => {
                            return {
                                role: message.role,
                                content: message.content,
                            };
                        })
                    )
            );
            newMessages = [...messages, message, response];
            console.log(response);
            setMessages(newMessages);
            if (speak.isSpeak) {
                speaker.speak(response.content);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function resendMessage() {
        submitChat(messages[messages.length - 1].content);
    }

    return (
        <StyledCover id="cover">
            {/*<NewUpdate />*/}
            <Grid container sx={{height: "100%"}} id="container">
                <Grid item md={12} display="flex" flexDirection="row">
                    <Paper></Paper>
                    <StyledChatBox id="chatbox" sx={{backgroundColor: "inherit"}}>
                        <LeftDrawer/>
                        {/*<ChatBox open={openChatBox} setOpen={setOpenChatBox} />*/}
                        <Box sx={{display: {xs: "block", md: "none"}}}>
                            <LoginButton/>
                        < /Box>
                        <RightDrawer/>
                        <ChatBoard
                            messages={messages}
                            loading={gptAPI.loading}
                            chunkMessage={gptAPI.chunkMessage}
                        />
                        {/* {messages[messages.length - 1].role === "user" &&
              !gptAPI.replying && (
                <ResendButton onClick={resendMessage}></ResendButton>
              )} */}
                        <ChatForm onSubmit={submitChat}/>
                        {/* {gptAPI.replying && <CancelButton cancel={gptAPI.cancel} />} */}
                        <ScrollDownButton/>
                    </StyledChatBox>
                </Grid>
            </Grid>
        </StyledCover>
    );
};

export default ChatGPTTC;
