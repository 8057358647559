import KeyIcon from "@mui/icons-material/Key";
import { Fab } from "@mui/material";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import useAuthAPI from "../../../HTTP_Request/useAuthAPI";
import Login from "../../../Component/Navbar/CustomerInfo/Login";
import UserAvatar from "../../../Component/Navbar/CustomerInfo/UserAvatar";

const styleFab = {
  position: "absolute",
  right: 60,
  color: "#e600ac",
  top: 5,
  zIndex: 999,
  opacity: 1,
};

function LoginButton() {
  const isLogin = useSelector((redux) => redux.auth.isLogin);
  const [isOpen, setOpen] = React.useState(false);
  const authAPI = useAuthAPI();
  function HandlerLogOut() {
    authAPI.logout();
  }

  // Everytime reload page then check and refresh token
  React.useEffect(() => {
    authAPI.refreshToken();
  }, []);

  return (
    <Fragment>
      <Fab sx={styleFab} size="small">
        {!isLogin && <KeyIcon onClick={() => setOpen(true)} />}
        {isLogin && <UserAvatar LogOut={HandlerLogOut} />}
      </Fab>
      <Login open={isOpen} setOpen={setOpen} />
    </Fragment>
  );
}

export default LoginButton;
