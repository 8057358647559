import { Fragment, useState } from "react";
import { Card, CardContent, Divider, Link, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { Box } from "@mui/system";
import CommonModal from "../../Component/UIComponent/Common/CommonModal";

const UpdateCard = ({ releaseDate, versionNumber, features }) => {
  return (
    <Fragment>
      <Divider sx={{ marginTop: 2 }}>Version {versionNumber}</Divider>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h3">
            Release Date: {releaseDate}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            New Features:
          </Typography>
          {features.map((feature, index) => (
            <Box key={index} my={1}>
              {feature}
            </Box>
          ))}
        </CardContent>
      </Card>
    </Fragment>
  );
};
const StyledTutorialBox = styled(Box)(({ theme }) => ({
  width: "800px",
  height: "500px",
  [theme.breakpoints.down("sm")]: {
    width: "350px",
    height: "400px",
  },
}));

const NewUpdate = (props) => {
  const [open, setOpen] = useState(true);
  return (
    <CommonModal open={open} setOpen={setOpen}>
      <Typography variant="h3" color="primary" textAlign="center">
        New Update
      </Typography>
      <StyledTutorialBox>
        <UpdateCard
          releaseDate="18/3/2023"
          versionNumber="Welcome"
          features={[
            "Hi. If this is your first time visiting my page, welcome!",
            "This DEMO page provides some free API tokens for you to try. Simply enter 'freetoken' in the token field on the right to enjoy.",
            "Of course, you can also use your own API key if you want.",
            "I built this page using ReactJS and am currently developing a full-stack of this",
            "My page is open source and the full-stack version will be released soon.",
            <Link href="https://github.com/thangchiba/ReactJS-ChatGPT">
              https://github.com/thangchiba/ReactJS-ChatGPT
            </Link>,
          ]}
        />
        <UpdateCard
          releaseDate="18/3/2023"
          versionNumber="Beta 0.0.5"
          features={[
            <Typography>
              GPT-4 Selection: You need invited by openai to use this future.
            </Typography>,
          ]}
        />
        <UpdateCard
          releaseDate="9/3/2023"
          versionNumber="Beta 0.0.4"
          features={[
            <Typography>Teach Feature（Left Bar）</Typography>,
            <Typography>Setting move to Right Bar</Typography>,
            <Typography>Speak Voice now can findable and select</Typography>,
            <Typography>Show/Disable Avatar</Typography>,
          ]}
        />
        <UpdateCard
          releaseDate="6/3/2023"
          versionNumber="Beta 0.0.3"
          features={[
            <Typography>Streamable</Typography>,
            <Typography>Response chunk cutting</Typography>,
            <Typography>Save data in local storage</Typography>,
          ]}
        />
        <UpdateCard
          releaseDate="5/3/2023"
          versionNumber="Beta 0.0.2"
          features={[
            <Typography>Migrate from homepage to separated page</Typography>,
          ]}
        />
        <UpdateCard
          releaseDate="4/3/2023"
          versionNumber="Beta 0.0.1"
          features={[
            <Typography>Chatable overthrough openai", "Speakable</Typography>,
          ]}
        />
      </StyledTutorialBox>
    </CommonModal>
  );
};
export default NewUpdate;
