import React, { Fragment, useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import ConfirmModal from "../../../Component/UIComponent/Common/ConfirmModal";

const ClearMessagesButton = () => {
  let dispatch = useDispatch();
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleClearChat = () => {
    localStorage.removeItem("messages"); // clear all localStorage Messages
    window.location.reload();
    dispatch();
  };

  return (
    <Fragment>
      <Button
        variant="outlined"
        color="error"
        onClick={() => setOpenConfirm(true)}
        startIcon={<DeleteIcon />}
      >
        Clear Conversations
      </Button>
      <ConfirmModal
        open={openConfirm}
        setOpen={setOpenConfirm}
        onAccept={handleClearChat}
      >
        Are you sure clear all conversations?
      </ConfirmModal>
    </Fragment>
  );
};
export default ClearMessagesButton;
