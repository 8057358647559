import styled from "@emotion/styled";
import {Modal, Typography} from "@mui/material";
import {Box} from "@mui/system";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const StyledBox = styled(Box)(({theme}) => ({
    position: "relative",
    backgroundColor: "white",
    minWidth: 350,
    maxHeight: "75vh",
    borderRadius: 20,
    padding: 10,
    overflow: "scroll",
    transform: 0.1,
    [theme.breakpoints.down("md")]: {
        minWidth: 350,
        maxWidth: 400,
    },
}));

function CommonModal(props) {
    const {setOpen, onClose, closeButton = true, closeWhenClickOutside = true} = props;

    function closeModal() {
        if (setOpen) setOpen(false);
        if (onClose) onClose();
    }

    return (
        <Modal
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
            onBackdropClick={closeWhenClickOutside && closeModal}
            {...props}
        >
            <StyledBox>
                <Box display="flex" justifyContent="end" style={{position: "absolute", right: 10}}>
                    {closeButton && (
                        <CloseOutlinedIcon
                            fontSize="large"
                            onClick={closeModal}
                            cursor="pointer"
                        />
                    )}
                </Box>
                {props.children}
            </StyledBox>
        </Modal>
    );
}

export default CommonModal;
