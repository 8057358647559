import React, {useState} from 'react';
import {Formik, Form, Field, useField} from 'formik';
import * as Yup from 'yup';
import {
    Button,
    Checkbox, debounce,
    FormControl,
    FormControlLabel, InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField, Typography
} from "@mui/material";
import {Box} from "@mui/system";
import useAuthAPI from "../../../../HTTP_Request/useAuthAPI";
import {CheckBox} from "@mui/icons-material";
import APIEndpoint from "../../../../HTTP_Request/APIEndpoint";

function SignUpForm(props) {
    const {setSignUp} = props;
    const authAPI = useAuthAPI();

    const initialValues = {
        username: '',
        fullName: "",
        email: '',
        subscribedForUpdates: true,
        // phone: "",
        gender: "undefined",
        address: "",
        password: '',
        confirmPassword: ''
    };

    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        email: Yup.string().email('Invalid email format').nullable(),
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
        // phone: Yup.number().typeError('Phone must be a number').nullable(),
    });

    const onSubmit = async (values, actions) => {
        const response = await authAPI.signUp(values);
        if (response) setSignUp(false);
        console.log(values);

        actions.setSubmitting(false);
        actions.resetForm();
    };
    const ConfigureCheckBox = ({label, ...props}) => {
        const [field] = useField({...props, type: 'checkbox'});
        return (
            <FormControlLabel
                control={<Checkbox {...field} />}
                label={label}
            />
        );
    };
    const [usernameStatus, setUsernameStatus] = useState(null);

    // Debounced API call function
    const checkUsername = debounce(async (username) => {
        try {
            const response = await fetch(`${APIEndpoint.ThangChibaAuth}/check-username?username=${username}`);
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            const data = await response.json();
            setUsernameStatus(data.exists ? 'invalid' : 'valid');
        } catch (error) {
            console.error('Error checking username:', error);
        }
    }, 500);
    return (
        <Box sx={{m: 2}}>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({values, errors, touched, isSubmitting, setFieldValue}) => (
                    <Form>
                        <Stack spacing={2}>
                            <Field
                                as={TextField}
                                name="username"
                                label="Username"
                                fullWidth
                                error={touched.username && !!errors.username}
                                helperText={touched.username && errors.username}
                                size="small"
                                required
                                onBlur={(e) => {
                                    setFieldValue('username', e.target.value); // Update Formik value
                                    checkUsername(e.target.value); // Trigger username check
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        {usernameStatus === 'valid' && <span style={{color: 'pink'}}>✔️</span>}
                                        {usernameStatus === 'invalid' &&
                                            <span style={{color: 'red'}}>❌</span>}</InputAdornment>,
                                }}
                            />
                            <Field
                                as={TextField}
                                name="fullName"
                                label="Full Name"
                                fullWidth
                                error={touched.fullName && !!errors.fullName}
                                helperText={touched.fullName && errors.fullName}
                                size="small"
                            />
                            <Field
                                as={TextField}
                                name="password"
                                type="password"
                                label="Password"
                                fullWidth
                                error={touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                size="small"
                                required
                            />
                            <Field
                                as={TextField}
                                name="confirmPassword"
                                type="password"
                                label="Re-Enter Password"
                                fullWidth
                                error={touched.confirmPassword && !!errors.confirmPassword}
                                helperText={touched.confirmPassword && errors.confirmPassword}
                                size="small"
                                required
                            />
                            <Field
                                as={TextField}
                                name="email"
                                label="Email"
                                fullWidth
                                error={touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                size="small"
                            />
                            {/*<Field*/}
                            {/*    as={TextField}*/}
                            {/*    name="phone"*/}
                            {/*    label="Phone Number"*/}
                            {/*    fullWidth*/}
                            {/*    error={touched.phone && !!errors.phone}*/}
                            {/*    helperText={touched.phone && errors.phone}*/}
                            {/*    size="small"*/}
                            {/*/>*/}
                            <FormControl fullWidth>
                                <InputLabel id="gender-label">Gender</InputLabel>
                                <Select
                                    labelId="gender-label"
                                    id="gender"
                                    label="Gender"
                                    value={values.gender}
                                    onChange={(e) => setFieldValue('gender', e.target.value)}
                                    size="small"
                                >
                                    <MenuItem value="male">Male</MenuItem>
                                    <MenuItem value="female">Female</MenuItem>
                                    <MenuItem value="undefined">Prefer not to say</MenuItem>
                                </Select>
                            </FormControl>
                            <Field
                                as={TextField}
                                name="address"
                                label="Address"
                                fullWidth
                                error={touched.address && !!errors.address}
                                helperText={touched.address && errors.address}
                                size="small"
                            />
                            {(values.email && !errors.email) && < ConfigureCheckBox
                                name="subscribedForUpdates"
                                label="Subscribe email for updates"
                            />}
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting}
                            >
                                Submit
                            </Button>
                            <Button
                                onClick={() => setSignUp(false)}
                                variant="outlined"
                            >
                                Back to Login
                            </Button>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </Box>
    );
}

export default SignUpForm;
