import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {Button, Divider, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {useState} from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// import FacebookLogin from 'react-facebook-login';
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import useAuthAPI from "../../../../HTTP_Request/useAuthAPI";
import {GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google';
import {FacebookLoginButton} from "react-social-login-buttons";
import SignupButton from "../../../../Auth/ButtonLogin/SignupButton";


function LoginForm(props) {
    const {setSignUp, setOpen} = props;
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const fromPath = location?.state?.from?.pathname;
    const authAPI = useAuthAPI();

    async function signIn() {
        const response = await authAPI.signIn(username, password);
        if (response) setOpen(false);
        // fromPath ? navigate(fromPath) : navigate("/home");
    }

    const handleResponseFacebook = async (response) => {
        console.log("Login FB Success : ", response);
        const loginSuccess = await authAPI.loginOAuth("facebook", response.accessToken);
        if (loginSuccess) setOpen(false);
    };
    const handleResponseGoogle = async (response) => {
        console.log("Login Google Success : ", response);
        const loginSuccess = await authAPI.loginOAuth("google", response.credential);
        if (loginSuccess) setOpen(false);
    };

    function onFailure(err) {
        alert(err);
    }

    return (
        <Box marginTop={3} display="flex" justifyContent="center">
            <Stack spacing={2} sx={{width: "80%"}}>
                <TextField
                    id="input-with-icon-textfield"
                    placeholder="Tài khoản"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircleOutlinedIcon/>
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />

                <TextField
                    id="input-with-icon-textfield"
                    placeholder="Mật khẩu"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockOutlinedIcon/>
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                    value={password}
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                    variant="outlined"
                    color={"success"}
                    onClick={() => {
                        signIn();
                    }}
                >
                    Sign in
                </Button>
                <Button
                    display="flex"
                    variant="outlined"
                    onClick={() => {
                        setSignUp(true);
                    }}
                >
                    Sign up
                </Button>
                <Box display="flex" alignItems={"center"} justifyContent="center">
                    <Stack spacing={1}>
                        <Divider>Or</Divider>
                        {/*<FacebookLogin*/}
                        {/*    appId={process.env.REACT_APP_FACEBOOK_LOGIN_APP_ID}*/}
                        {/*    fields="name,email,picture"*/}
                        {/*    callback={handleResponseFacebook}*/}
                        {/*    render={renderProps => (*/}
                        {/*        <FacebookLoginButton text={"Login with Facebook"} onClick={renderProps.onClick} style={{*/}
                        {/*            backgroundColor: "pink",*/}
                        {/*            color: "white",*/}
                        {/*            width: 260,*/}
                        {/*            margin: 0,*/}
                        {/*            height: 40*/}
                        {/*        }}*/}
                        {/*                             activeStyle={{backgroundColor: "black"}}/>*/}
                        {/*    )}*/}
                        {/*    autoLoad={false}*/}
                        {/*    onFailure={onFailure}*/}
                        {/*    disableMobileRedirect={true}*/}
                        {/*/>*/}
                        <GoogleOAuthProvider clientId={process.env.REACT_APP_GG_APP_ID || ''}>
                            <GoogleLogin
                                onSuccess={handleResponseGoogle}
                                onError={() => {
                                    alert('Login Failed');
                                }}
                                logo_alignment={"center"}
                                width={260}
                            />
                        </GoogleOAuthProvider>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    );
}

export default LoginForm;
