import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import APIEndpoint from "./APIEndpoint";
import useHTTP from "./useHTTP";

const useHTTPGPTTC = () => {
  const endpoint = APIEndpoint.ThangChibaGPT;
  const accessToken = useSelector((redux) => redux.auth.accessToken);
  const accessKey = useSelector((redux) => redux.auth.accessKey);
  const model = useSelector((redux) => redux.gpt.model);
  const max_tokens = useSelector((redux) => redux.gpt.maxGenToken);
  const temperature = useSelector((redux) => redux.gpt.temperature);
  const [loading, setLoading] = useState(false);
  const [replying, setReplying] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);

  function cancel() {
    setIsCanceled(true);
    setReplying(false);
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken,
    AccessKey: accessKey,
  };

  const { post, error } = useHTTP({ headers, responseType: "stream" });

  const [chunkMessage, setChunkMessage] = useState(null);

  const sendRequest = async (messages = null) => {
    setLoading(true);
    setIsCanceled(false);
    const body = {
      model,
      messages,
      max_tokens,
      temperature,
      stream: true,
    };
    const response = await post(endpoint, body);
    if (!response.ok) {
      if (response.status === 401) toast("Access Token is not valid!");
      // console.log(response);
      // toast(response.detail);
    }
    const stream = response.body.getReader();
    const responseMessage = await readStreamPromise(stream, setChunkMessage);
    return responseMessage;
  };

  function readStreamPromise(reader, setChunkMessage) {
    let chunksReceived = [];
    let responseMessage = null;
    const decoder = new TextDecoder("utf-8");
    return new Promise((resolve, reject) => {
      function readStream() {
        console.log("call read" + isCanceled);
        if (isCanceled) resolve(responseMessage);
        reader
          .read()
          .then(({ done, value }) => {
            setLoading(false);
            setReplying(true);
            if (done) {
              setChunkMessage(null);
              resolve(responseMessage);
              setReplying(false);
              return;
            }
            const chunk = decoder.decode(value);
            const chunks = chunk.split("data: ");
            let listChunk = chunks.slice(1).map((x) => {
              try {
                return JSON.parse(x);
              } catch {
                return null;
              }
            });
            chunksReceived = chunksReceived.concat(listChunk);
            const message = chunksReceived
              .map((chunk) =>
                chunk ? chunk.choices[0].delta.content || "" : ""
              )
              .join("");
            responseMessage = {
              id: chunksReceived[0].id,
              role: chunksReceived[0].choices[0].delta.role,
              content: message,
            };
            setChunkMessage(responseMessage);
            readStream();
          })
          .catch((error) => {
            reject(error); // handle any errors that occur during reading
          });
      }
      readStream();
    });
  }
  return {
    post: (body) => sendRequest(body),
    chunkMessage,
    loading,
    error,
    cancel,
    replying,
  };
};

export default useHTTPGPTTC;
