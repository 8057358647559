import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import MaxGenToken from "./MaxGenToken";
import Temperature from "./Temperature";
import SetupModel from "../../../../ChatGPT/RightDraw/Setting/SetupModel";

const Advance = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        sx={{ backgroundColor: "inherit" }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color="white">Advance Setting</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <MaxGenToken />
            <Temperature />
            <SetupModel />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Advance;
