const APIEndpoint = {
    GPTTurbo: "https://api.openai.com/v1/chat/completions",
    ThangChibaGPT: "https://api.thangchiba.com/ai/api/v1/chat",
    // ThangChibaGPT: "http://127.0.0.1:8000/ai/api/v1/chat",
    ThangChibaAuth: "https://api.thangchiba.com/auth",
    // ThangChibaAuth: "https://localhost:56780/auth",
    // ThangChibaAuth: "https://192.168.1.74:56780/auth",
    // ThangChibaOAuth: "https://localhost:56780/oauth",
    ThangChibaOAuth: "https://api.thangchiba.com/oauth",
};
export default APIEndpoint;
