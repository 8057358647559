import { InputLabel, Slider } from "@mui/material";
import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gptAction } from "../../../../../Redux/GPTSlice";

const Temperature = () => {
  const dispatch = useDispatch();
  const temperature = useSelector((redux) => redux.gpt.temperature);
  const handleTemperatureChange = (event) => {
    dispatch(gptAction.setTemperature(event.target.value));
  };

  return (
    <div>
      <InputLabel
        sx={{ color: "white" }}
        id="temperature-slider-label"
      >{`Temperature : ${temperature}`}</InputLabel>
      <Slider
        value={temperature}
        onChange={handleTemperatureChange}
        aria-labelledby="temperature-slider-label"
        step={0.1}
        min={0.1}
        max={2.0}
        color="warning"
      />
    </div>
  );
};
export default Temperature;
