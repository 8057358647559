import {Divider, Typography} from "@mui/material";
import {Box} from "@mui/system";
import React from "react";
import cumeoLogo from "../../../../Static/Images/Logo/cumeo128.png";
import CommonModal from "../../../../Component/UIComponent/Common/CommonModal";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";
import useAuthAPI from "../../../../HTTP_Request/useAuthAPI";

function Login(props) {
    const {setOpen, open} = props;
    const [isSignUp, setSignUp] = React.useState(false);

    return (
        <CommonModal open={open} setOpen={setOpen} closeWhenClickOutside={false}>
            {!isSignUp && <Box display="flex" flexDirection="column" alignItems="center">
                <img src={cumeoLogo} height={70} width={70}/>
            </Box>}

            <Typography variant="h3" textAlign="center">
                {!isSignUp ? "Welcome" : "Sign up"}
            </Typography>
            <Divider/>
            {!isSignUp && <LoginForm setSignUp={setSignUp} setOpen={setOpen}/>}
            {isSignUp && <SignUpForm setSignUp={setSignUp} setOpen={setOpen}/>}
        </CommonModal>
    );
}

export default Login;
